<template>
  <div>
    <div class="pane">
      <div class="title">{{ typeObject.name }}</div>
      <div class="remarks">{{ typeObject.remarks }}</div>
    </div>

    <a-card class="container">
      <Header :title="typeObject.title" />

      <a-form
        :form="form"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 12 }"
        :colon="false"
        @submit="handleSubmit"
      >
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="申请人">
              <a-button block style="text-align: left">
                {{ user.userName }}
              </a-button>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="申请部门">
              <a-button block style="text-align: left">
                {{ department.deptUniqueName }}
              </a-button>
            </a-form-item>
          </a-col>

          <template v-if="isYear">
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="年假额度">
                <a-button block style="text-align: left"
                  >{{ yearLength }}天</a-button
                >
              </a-form-item>
            </a-col>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="可用年假">
                <a-button block style="text-align: left"
                  >{{ yearResidue }}天</a-button
                >
              </a-form-item>
            </a-col>
          </template>

          <template
            v-if="
              isYear ||
              isThing ||
              isSick ||
              isMarry ||
              isCheck ||
              isPaternity ||
              isDie ||
              isCarry ||
              isTakeCare ||
              isOut ||
              isBusiness
            "
          >
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item style="margin-bottom: 0">
                <span class="required" slot="label">开始时间</span>
                <a-form-item
                  :style="{
                    display: 'inline-block',
                    width: 'calc(50% - 12px)',
                  }"
                >
                  <a-date-picker
                    style="width: 100%"
                    :disabled="disabled"
                    placeholder
                    v-decorator="[
                      'startDate',
                      {
                        initialValue: detail.startTime
                          ? moment(detail.startTime)
                          : undefined,
                        rules: [{ required: true, message: '请选择！' }],
                      },
                    ]"
                  />
                </a-form-item>
                <span
                  :style="{
                    display: 'inline-block',
                    width: '24px',
                    textAlign: 'center',
                  }"
                  >~</span
                >
                <a-form-item
                  :style="{
                    display: 'inline-block',
                    width: 'calc(50% - 12px)',
                  }"
                >
                  <a-select
                    style="width: 100%"
                    :disabled="disabled"
                    v-decorator="[
                      'startTime',
                      {
                        initialValue: detail.startTime
                          ? detail.startTime.split(' ').at(-1)
                          : undefined,
                        rules: [{ required: true, message: '请选择！' }],
                      },
                    ]"
                  >
                    <a-select-option
                      v-for="item in isOut ? getOutTimeList() : startTimeList"
                      :key="item.value"
                      :value="item.value"
                      >{{ item.label }}</a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item style="margin-bottom: 0">
                <span class="required" slot="label">结束时间</span>
                <a-form-item
                  :style="{
                    display: 'inline-block',
                    width: 'calc(50% - 12px)',
                  }"
                >
                  <a-date-picker
                    style="width: 100%"
                    :disabled="disabled"
                    placeholder
                    v-decorator="[
                      'endDate',
                      {
                        initialValue: detail.endTime
                          ? moment(detail.endTime)
                          : undefined,
                        rules: [{ required: true, message: '请选择！' }],
                      },
                    ]"
                  />
                </a-form-item>
                <span
                  :style="{
                    display: 'inline-block',
                    width: '24px',
                    textAlign: 'center',
                  }"
                  >~</span
                >
                <a-form-item
                  :style="{
                    display: 'inline-block',
                    width: 'calc(50% - 12px)',
                  }"
                >
                  <a-select
                    style="width: 100%"
                    :disabled="disabled"
                    v-decorator="[
                      'endTime',
                      {
                        initialValue: detail.endTime
                          ? detail.endTime.split(' ').at(-1)
                          : undefined,
                        rules: [{ required: true, message: '请选择！' }],
                      },
                    ]"
                  >
                    <a-select-option
                      v-for="item in isOut ? getOutTimeList() : endTimeList"
                      :key="item.value"
                      :value="item.value"
                      >{{ item.label }}</a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-form-item>
            </a-col>
          </template>

          <template
            v-if="isOvertime || isDaysOff"
          >
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item style="margin-bottom: 0">
                <span class="required" slot="label">时间段</span>
                <div v-for="(item,index) in selectTimeList" :key="index" style="width: 500px">
                  <a-form-item
                    :style="{
                      marginRight: '10px',
                      display: 'inline-block',
                      width: '130px',
                    }"
                  >
                    <a-date-picker
                      style="width: 100%"
                      :disabled="disabled"
                      v-model="item.date"
                    />
                  </a-form-item>
                  <a-form-item
                    :style="{
                      display: 'inline-block',
                      width: '110px',
                    }"
                  >
                    <a-time-picker :disabled="disabled" style="width: 100%;"
                      placeholder="开始时间"
                      v-model="item.startTime"
                      format="HH:mm" />
                  </a-form-item>
                  <span
                    :style="{
                      display: 'inline-block',
                      width: '24px',
                      textAlign: 'center',
                    }"
                    >~</span
                  >
                  <a-form-item
                    :style="{
                      display: 'inline-block',
                      width: '110px',
                    }"
                  >
                  <a-time-picker :disabled="disabled" style="width: 100%;"
                      placeholder="结束时间"
                      v-model="item.endTime"
                      format="HH:mm" />
                  </a-form-item>
                  <a-form-item
                    :style="{
                      display: 'inline-block',
                    }"
                  >
                    <a-button v-if="index == 0" shape="circle" icon="plus" size="small" style="margin-left: 10px;" @click="addSelectTime()"/>
                    <a-button v-if="index>0" shape="circle" icon="minus" size="small" style="margin-left: 10px;" @click="removeSelectTime(index)"/>
                  </a-form-item>
                </div>
              </a-form-item>
            </a-col>
          </template>

          <a-col :lg="12" :md="12" :sm="24" v-if="isFeed">
            <a-form-item label="开始日期">
              <a-date-picker
                style="width: 100%"
                :disabled="disabled"
                placeholder
                v-decorator="[
                  'startDate',
                  {
                    initialValue: detail.startTime
                      ? moment(detail.startTime)
                      : undefined,
                    rules: [{ required: true, message: '请选择！' }],
                  },
                ]"
                @change="onDateChange"
              />
            </a-form-item>
          </a-col>

          <template v-if="isFeed">
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="结束日期">
                <a-button style="text-align: left" block>{{
                  endDateStr
                }}</a-button>
              </a-form-item>
            </a-col>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="时长(天)">
                <a-button style="text-align: left" block>{{ length }}</a-button>
              </a-form-item>
            </a-col>
          </template>

          <a-col :lg="12" :md="12" :sm="24" v-if="isBirth">
            <a-form-item label="类型">
              <a-select
                mode="multiple"
                :disabled="disabled"
                v-decorator="['optionList']"
                @change="onTypeChange"
              >
                <a-select-option
                  v-for="item in [...birthTypeList, ...children, ...errorList]"
                  :key="item.label"
                  :value="item.label"
                  >{{ item.label }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>

          <template v-if="isBirth">
            <template
              v-if="
                form.getFieldValue('optionList') &&
                (form.getFieldValue('optionList').indexOf('不满2个月终止妊娠') >
                  -1 ||
                  form
                    .getFieldValue('optionList')
                    .indexOf('满2个月不满3个月终止妊娠') > -1 ||
                  form
                    .getFieldValue('optionList')
                    .indexOf('满3个月不满7个月终止妊娠') > -1 ||
                  form
                    .getFieldValue('optionList')
                    .indexOf('怀孕满7个月终止妊娠') > -1)
              "
            >
              <a-col :lg="12" :md="12" :sm="24">
                <a-form-item style="margin-bottom: 0">
                  <span class="required" slot="label">开始时间</span>
                  <a-form-item
                    :style="{
                      display: 'inline-block',
                      width: 'calc(50% - 12px)',
                    }"
                  >
                    <a-date-picker
                      style="width: 100%"
                      :disabled="disabled"
                      placeholder
                      v-decorator="[
                        'startDate',
                        {
                          initialValue: detail.startTime
                            ? moment(detail.startTime)
                            : undefined,
                          rules: [{ required: true, message: '请选择！' }],
                        },
                      ]"
                    />
                  </a-form-item>
                  <span
                    :style="{
                      display: 'inline-block',
                      width: '24px',
                      textAlign: 'center',
                    }"
                    >~</span
                  >
                  <a-form-item
                    :style="{
                      display: 'inline-block',
                      width: 'calc(50% - 12px)',
                    }"
                  >
                    <a-select
                      style="width: 100%"
                      :disabled="disabled"
                      v-decorator="[
                        'startTime',
                        {
                          initialValue: detail.startTime
                            ? detail.startTime.split(' ').at(-1)
                            : undefined,
                          rules: [{ required: true, message: '请选择！' }],
                        },
                      ]"
                    >
                      <a-select-option
                        v-for="item in startTimeList"
                        :key="item.value"
                        :value="item.value"
                        >{{ item.label }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                </a-form-item>
              </a-col>

              <a-col :lg="12" :md="12" :sm="24">
                <a-form-item style="margin-bottom: 0">
                  <span class="required" slot="label">结束时间</span>
                  <a-form-item
                    :style="{
                      display: 'inline-block',
                      width: 'calc(50% - 12px)',
                    }"
                  >
                    <a-date-picker
                      style="width: 100%"
                      :disabled="disabled"
                      placeholder
                      v-decorator="[
                        'endDate',
                        {
                          initialValue: detail.endTime
                            ? moment(detail.endTime)
                            : undefined,
                          rules: [{ required: true, message: '请选择！' }],
                        },
                      ]"
                    />
                  </a-form-item>
                  <span
                    :style="{
                      display: 'inline-block',
                      width: '24px',
                      textAlign: 'center',
                    }"
                    >~</span
                  >
                  <a-form-item
                    :style="{
                      display: 'inline-block',
                      width: 'calc(50% - 12px)',
                    }"
                  >
                    <a-select
                      style="width: 100%"
                      :disabled="disabled"
                      v-decorator="[
                        'endTime',
                        {
                          initialValue: detail.endTime
                            ? detail.endTime.split(' ').at(-1)
                            : undefined,
                          rules: [{ required: true, message: '请选择！' }],
                        },
                      ]"
                    >
                      <a-select-option
                        v-for="item in endTimeList"
                        :key="item.value"
                        :value="item.value"
                        >{{ item.label }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                </a-form-item>
              </a-col>
            </template>
            <template v-else>
              <a-col :lg="12" :md="12" :sm="24">
                <a-form-item label="开始日期">
                  <a-date-picker
                    style="width: 100%"
                    :disabled="disabled"
                    placeholder
                    v-decorator="[
                      'startDate',
                      {
                        initialValue: detail.startTime
                          ? moment(detail.startTime)
                          : undefined,
                        rules: [{ required: true, message: '请选择！' }],
                      },
                    ]"
                    @change="onDateChange"
                  />
                </a-form-item>
              </a-col>

              <a-col :lg="12" :md="12" :sm="24">
                <a-form-item label="结束日期">
                  <a-button style="text-align: left" block>{{
                    endDateStr
                  }}</a-button>
                </a-form-item>
              </a-col>
              <a-col :lg="12" :md="12" :sm="24">
                <a-form-item label="时长(天)">
                  <a-button style="text-align: left" block>{{
                    length
                  }}</a-button>
                </a-form-item>
              </a-col>
            </template>
          </template>

          <a-col :lg="12" :md="12" :sm="24" v-if="isDie">
            <a-form-item label="类型">
              <a-select
                style="width: 100%"
                :disabled="disabled"
                v-decorator="[
                  'option',
                  {
                    initialValue: detail.option,

                    rules: [{ required: true, message: '请选择！' }],
                  },
                ]"
              >
                <a-select-option
                  v-for="item in [
                    {
                      label: '其他亲属',
                    },
                    {
                      label: '直系亲属',
                    },
                  ]"
                  :key="item.label"
                  :value="item.label"
                  >{{ item.label }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24" v-if="isBusiness">
            <a-form-item label="交通工具(往)">
              <a-auto-complete
                :disabled="disabled"
                :data-source="['飞机', '火车', '汽车', '单位用车']"
                v-decorator="[
                  'trafficTo',
                  {
                    initialValue: detail.trafficTo,
                    rules: [{ required: true, message: '请输入' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24" v-if="isBusiness">
            <a-form-item label="交通工具(返)">
              <a-auto-complete
                :disabled="disabled"
                :data-source="['飞机', '火车', '汽车', '单位用车']"
                v-decorator="[
                  'trafficBack',
                  {
                    initialValue: detail.trafficBack,
                    rules: [{ required: true, message: '请输入' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24" v-if="isBusiness">
            <a-form-item
              label="费用预算(元)"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-input-number
                style="width: 100%"
                :disabled="disabled"
                v-decorator="[
                  'cost',
                  {
                    initialValue: detail.cost,
                    rules: [{ required: true, message: '请输入' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24" v-if="isBusiness || isOut">
            <a-form-item
              :label="isBusiness ? '出差地点' : '公出地点'"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-input
                :disabled="disabled"
                v-decorator="[
                  'targetAddr',
                  {
                    initialValue: detail.targetAddr,
                    rules: [{ required: true, message: '请输入' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              :label="isBusiness ? '出差事由' : isOut ? '公出事由' : '情况说明'"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-textarea
                :auto-size="{ minRows: 3 }"
                v-decorator="[
                  'remark',
                  {
                    initialValue: detail.remark,
                    rules: [{ required: true, message: '请输入' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24" v-if="isBusiness">
            <a-form-item
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
              label="是否驻场"
            >
            <a-radio-group
              v-decorator="[
                'isResident',
                {
                  initialValue: detail.isResident,
                    rules: [{ required: true, message: '请选择' }] },
              ]"
            >
            <a-radio :value="1">是</a-radio>
            <a-radio :value="0">否</a-radio>
          </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24" v-if="isBusiness">
            <a-form-item
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
              label="同行人员"
            >
              <StaffList
                :list="staffList"
                @change="onStaffChange"
                :disabled="isBusinessDisabled"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24" v-if="isBusiness">
            <a-form-item
              label="备注"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
            >
              <div class="tips" style="color: red">
                系统会为同行人员中同部门的人创建出差记录，如有其他部门的同事，请让他单独申请；提交后无法变更同行人员，如需变更，请在审批结束前点击终止按钮，重新发起。审批通过后，请发起撤销申请和新的出差申请。
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
              label="附件"
            >
              <Upload
                :list="fileList"
                @change="onChange"
                :disabled="disabled"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24" v-if="isYear && thisYear.base">
            <a-form-item
              label="备注"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
            >
              <div class="tips">
                <template v-if="!isExpired">
                  去年年假额度
                  <span style="color: red">{{ lastYear.base }}</span> 天，可用
                  <span style="color: red">{{ lastYear.generated }}</span> 天，用了
                  <span style="color: red">{{ lastYear.used }}</span> 天， 还剩
                  <span style="color: red">{{
                    lastYear.left
                  }}</span>
                  天；
                </template>
                今年年假额度
                <span style="color: red">{{ thisYear.base }}</span> 天， 用了
                <span style="color: red">{{ thisYear.used }}</span> 天， 还剩
                <span style="color: red">{{
                  thisYear.base - thisYear.used
                }}</span>
                天， 由于年假不可提前申请，截止到今天，今年年假可用
                <span style="color: red">{{ thisYear.generated }}</span> 天，
                已用 <span style="color: red">{{ thisYear.used }}</span> 天，
                所以你可以申请今年的年假
                <span style="color: red">{{ thisYear.left }}</span> 天。
              </div>
            </a-form-item>
          </a-col>
          <a-col :lg="24" :md="24" :sm="24" v-if="isOvertime">
            <a-form-item
              label="提醒"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
            >
              <div class="tips">
                <span style="color: red">加班申请不允许事后申请，未经审批通过视为无效加班</span>
              </div>
            </a-form-item>
          </a-col>
          <a-col :lg="24" :md="24" :sm="24" v-if="isDaysOff">
            <a-form-item
              label="备注"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
            >
              <div class="tips">
                当月调休额度
                <span style="color: red">{{ overtimeApplied }}</span> 分钟， 用了
                <span style="color: red">{{ daysOffUsed }}</span> 分钟， 还剩
                <span style="color: red">{{ daysOffBalance }}</span>分钟
              </div>
            </a-form-item>
          </a-col>
          <a-col :lg="24" :md="24" :sm="24" v-if="isFeed">
            <a-form-item
              label="备注"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
            >
              <div class="tips">
                哺乳假期间每天有一小时弹性上下班时间，如晚来或早退一小时，或晚来半小时并早退半小时，自由排列组合，按照实际上下班时间打卡即可
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24" v-if="isOut">
            <a-form-item
              label="备注"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
            >
              <div class="tips">
                <span>因公外出期间如果包含上下班时间点，则不用打卡</span>
              </div>
            </a-form-item>
          </a-col>
        </a-row>

        <div class="footer">
          <a-space>
            <a-button type="primary" html-type="submit" :loading="saveLoading"
              >提交</a-button
            >
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </a-card>
  </div>
</template>

<script>
import moment from "moment";
import Header from "./components/header";
import Upload from "./components/upload.vue";
import StaffList from "./components/staff-list.vue";

import {
  fetchUserHoliday,
  fetchYearDetail,
  fetchAdjust,
  addLeave,
  addAdjust,
  fetchLeaveDetail,
  resubmitLeave,
  fetchUserLeave,
} from "@/api/human-resources/clock";

import { mapGetters, mapState } from "vuex";

import { startTimeList, endTimeList, getOutTimeList, getOvertimeList } from "./utils/time";
import { birthTypeList, children, errorList } from "./utils/childbirth";

import holidayMixin from "./utils/index";

export default {
  components: {
    Header,
    Upload,
    StaffList,
  },
  mixins: [holidayMixin],
  data() {
    return {
      daysOffBalance: '',
      daysOffUsed: '',
      overtimeApplied: '',
      selectTimeList: [
        {
          date: '',
          startTime: '',
          endTime: ''
        }
      ],
      yearLength: 0,
      yearResidue: 0,

      lastYear: {},
      thisYear: {},

      isExpired: false, // 去年的年假是否已过期

      length: 0,
      endDateStr: "",

      type: "",

      form: this.$form.createForm(this),

      startTimeList,
      endTimeList,

      birthTypeList,
      children,
      errorList,

      fileList: [],
      staffList: [], // 出差同行人员

      saveLoading: false,

      detail: {}, // 出差变更详情
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapGetters("auth", ["department"]),

    gender() {
      return this.user && this.user.employeeVo && this.user.employeeVo.sex
        ? this.user.employeeVo.sex
        : "";
    },

    typeObject() {
      if (this.type === "days_off") {
        return {
          name: "调休",
          remarks: "调休页面",
          title: "调休申请单",
        };
      } else if (this.type === "overtime") {
        return {
          name: "加班",
          remarks: "加班页面",
          title: "加班申请单",
        };
      } else if (this.type === "annual") {
        return {
          name: "年假",
          remarks: "年假页面",
          title: "年假申请单",
        };
      } else if (this.type === "sick") {
        return {
          name: "病假",
          remarks: "病假页面",
          title: "病假申请单",
        };
      } else if (this.type === "personal") {
        return {
          name: "事假",
          remarks: "事假页面",
          title: "事假申请单",
        };
      } else if (this.type === "marriage") {
        return {
          name: "婚假",
          remarks: "婚假页面",
          title: "婚假申请单",
        };
      } else if (this.type === "maternity") {
        return {
          name: "产假",
          remarks: "产假页面",
          title: "产假申请单",
        };
      } else if (this.type === "prenatal_check_up") {
        return {
          name: "产检假",
          remarks: "产检假页面",
          title: "产检假申请单",
        };
      } else if (this.type === "paternity") {
        return {
          name: "护理假",
          remarks: "护理假页面",
          title: "护理假申请单",
        };
      } else if (this.type === "breast_feeding") {
        return {
          name: "哺乳假",
          remarks: "哺乳假页面",
          title: "哺乳假申请单",
        };
      } else if (this.type === "bereavement") {
        return {
          name: "丧假",
          remarks: "丧假页面",
          title: "丧假申请单",
        };
      } else if (this.type === "on_business_in") {
        return {
          name: "公出",
          remarks: "公出页面",
          title: "公出申请单",
        };
      } else if (this.type === "on_business_out") {
        return {
          name: "出差",
          remarks: "出差页面",
          title: "出差申请单",
        };
      } else if (this.type === "single_child_carry") {
        return {
          name: "独生子女带薪护理假",
          remarks: "独生子女带薪护理假页面",
          title: "独生子女带薪护理假申请单",
        };
      } else if (this.type === "childcare") {
        return {
          name: "育儿假",
          remarks: "育儿假页面",
          title: "育儿假申请单",
        };
      } else {
        return {};
      }
    },
    isOvertime() {
      return this.type === "overtime";
    },
    isDaysOff() {
      return this.type === "days_off";
    },
    isYear() {
      return this.type === "annual";
    },
    isThing() {
      return this.type === "personal";
    },
    isSick() {
      return this.type === "sick";
    },
    isMarry() {
      return this.type === "marriage";
    },
    isBirth() {
      return this.type === "maternity";
    },
    isCheck() {
      return this.type === "prenatal_check_up";
    },
    isPaternity() {
      return this.type === "paternity";
    },
    isFeed() {
      return this.type === "breast_feeding";
    },

    isDie() {
      return this.type === "bereavement";
    },

    isOut() {
      return this.type === "on_business_in";
    },

    isCarry() {
      return this.type === "single_child_carry";
    },
    isTakeCare() {
      return this.type === "childcare";
    },
    isBusiness() {
      return this.type === "on_business_out";
    },

    isBusinessDisabled() {
      return this.control === "resubmit" || !!this.id;
    },

    disabled() {
      return this.detail.optType === "cancel";
    },
  },
  mounted() {
    const now = moment();
    if (now.isSameOrAfter(now.format("YYYY") + "-07-01", "days")) {
      this.isExpired = true;
    }

    const { type, id, control } = this.$route.query;
    this.type = type;
    this.id = id;
    this.control = control;

    if (id) {
      const hide = this.$message.loading("加载中...");

      fetchLeaveDetail({
        id,
      })
        .then((res) => {
          this.detail = res || {};
          this.form.setFieldsValue({
            optionList: this.detail.option
              ? this.detail.option.split(",")
              : undefined,
          });

          if (Array.isArray(res.staffList)) {
            this.staffList = res.staffList.filter((item) => item.userId);
          }
          if (typeof this.detail.attachments === "string") {
            this.fileList = this.detail.attachments.split(",").map((item) => {
              return {
                completePath: item,
                name: this.$getFileName(item),
              };
            });
          }
        })
        .finally(() => {
          hide();
        });
    }

    if (this.type === "annual") {
      fetchUserHoliday({
        leaveTypeList: ["annual"],
      }).then((res) => {
        const { annual_base, annual } = res || {};
        this.yearLength = annual_base || 0;
        this.yearResidue = annual || 0;
      });

      fetchYearDetail().then((res) => {
        console.log("year detail", res);
        const { last, current } = res || {};
        this.lastYear = last || {};
        this.thisYear = current || {};
      });
    }
    if (this.type === "days_off") {
      fetchAdjust({
        userId: this.user.uuid,
        workDate: moment().format("YYYY-MM")
      }).then((res) => {
        this.overtimeApplied = res.overtimeApplied
        this.daysOffBalance = res.daysOffBalance
        this.daysOffUsed = res.daysOffUsed
      });
    }
  },
  methods: {
    getOutTimeList,
    getOvertimeList,
    moment,
    addSelectTime() {
      this.selectTimeList.push(
        {
          date: '',
          startTime: '',
          endTime: ''
        }
      )
    },
    removeSelectTime(index) {
      this.selectTimeList.splice(index, 1)
    },
    onStaffChange(list) {
      console.log("on staff change", list);
      this.staffList = list;
    },

    onChange(list) {
      this.fileList = list;
    },

    onDateChange(value) {
      if (!value) return;

      if (this.isBirth) {
        const optionList = this.form.getFieldValue("optionList");

        this.calculateBirth({
          startDate: value,
          optionList: Array.isArray(optionList) ? optionList : [],
        });
      } else if (this.isFeed) {
        const endDate = value.clone().add(1, "years").subtract(1, "days");

        this.length = endDate.diff(value, "days") + 1;
        this.endDateStr = endDate.format("YYYY-MM-DD");
      }
    },
    onTypeChange(value) {
      if (!value) return;

      const startDate = this.form.getFieldValue("startDate");

      this.calculateBirth({
        startDate,
        optionList: Array.isArray(value) ? value : [],
      });
    },

    calculateBirth(values) {
      if (!values.startDate) return;
      // 产假：158天，就是包括了周末的，但不包括法定节假日，所以孕妇最终能放的假期其实超过了158天，比如劳动节1天，国庆节3天
      // let len = 10;
      let len = 158;

      if (values.optionList) {
        let birthTypeLength = 0;
        let childrenLength = 0;
        let errorLength = 0;

        values.optionList.forEach((item) => {
          if (
            birthTypeList.findIndex((element) => element.label === item) > -1
          ) {
            birthTypeLength++;
          }
          if (children.findIndex((element) => element.label === item) > -1) {
            childrenLength++;
          }
          if (errorList.findIndex((element) => element.label === item) > -1) {
            errorLength++;
          }
        });

        if (errorLength > 0) {
          if (errorLength > 1) {
            this.$message.error("请选择一种终止妊娠");
          }

          if (childrenLength > 0 || birthTypeLength > 0) {
            this.$message.error("已选择终止妊娠，请手动取消其他类型");
          }

          return;
        }

        if (childrenLength > 0) {
          if (childrenLength > 1) {
            this.$message.error("请选择一种多胞胎");
            return;
          }
        }

        values.optionList.forEach((item) => {
          const element = birthTypeList.find(
            (element) => element.label === item
          );
          if (element) {
            len = len + element.value;
          }
          const element1 = children.find((element) => element.label === item);
          if (element1) {
            len = len + element1.value;
          }
        });
      }

      const startDate = values.startDate;
      const endDate = startDate.clone().add(len - 1, "days");

      console.log("startDate", startDate);
      console.log("endDate", endDate);

      const arr = [];

      for (let i = 0; i < this.holiday.length; i++) {
        const item = this.holiday[i];
        if (Array.isArray(item.data) && item.data.length > 0) {
          for (let j = 0; j < item.data.length; j++) {
            const element = item.data[j];
            console.log("element", element);

            if (Array.isArray(element.realHoliday)) {
              element.realHoliday.forEach((real) => {
                if (
                  startDate.isSameOrBefore(real, "days") &&
                  endDate.isSameOrAfter(real, "days")
                ) {
                  arr.push(real);
                }
              });
            }
          }
        }
      }

      console.log("arr", arr);
      console.log("old len", len);

      len =
        len +
        arr.filter((item, index, self) => self.indexOf(item) === index).length;

      console.log("new len", len);

      this.length = len;
      this.endDateStr = values.startDate
        .clone()
        .add(len - 1, "days")
        .format("YYYY-MM-DD");
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("values", values);

          if (this.isOvertime || this.isDaysOff) {
            this.saveLoading = true;
            const first = this.selectTimeList[0]
            const last = this.selectTimeList[this.selectTimeList.length - 1]
            const startDate = first.date;
            const endDate = last.date;
            const startTime = first.startTime;
            const endTime = last.endTime;
            let canSub = true
            let len = 0
            this.selectTimeList.forEach(v=>{
              const l = v.endTime.diff(v.startTime, 'minutes')
              if(l<=0){
                canSub = false
              }
              len = len + l
            })
            if(!canSub) {
              this.$message.error("时间段选择有误");
              this.saveLoading = false;
              return
            }
            console.log("len", len);

            if (!len) return;
            addAdjust({
              extra: JSON.stringify(this.selectTimeList.map(v=>{
                return {
                  date: v.date.format("YYYY-MM-DD"),
                  startTime: v.startTime.format("HH:mm"),
                  endTime: v.endTime.format("HH:mm"),
                  duration: v.endTime.diff(v.startTime, 'minutes')
                }
              })),
              startTime: startDate.format("YYYY-MM-DD") + " " + startTime.format("HH:mm:ss"),
              endTime: endDate.format("YYYY-MM-DD") + " " + endTime.format("HH:mm:ss"),
              type: this.type,
              duration: len,
              durationUnit: "min",
              remark: values.remark,
              attachments:
                this.fileList.length > 0
                  ? this.fileList.map((item) => item.completePath).join(",")
                  : undefined,
              userId: this.user.uuid,
              name: this.user.userName,
              deptId: this.department.deptId,
              deptName: this.department.deptName,
              optType: this.id ? "modify" : "add",
              pid: this.id ? this.id : undefined, // 出差变更申请需要根据 id 构造 pid
            })
              .then(() => {
                this.$router.go(-1);
              })
              .finally(() => {
                this.saveLoading = false;
              });
            return;
          }

          if (this.isOut) {
            const startDate = values.startDate.clone();
            const endDate = values.endDate.clone();
            const startTime = values.startTime;
            const endTime = values.endTime;

            const len = this.getOutLen({
              startDate,
              startTime,
              endDate,
              endTime,
            });

            console.log("len", len);

            if (!len) return;

            this.sendRequest({
              startTime: startDate.format("YYYY-MM-DD") + " " + startTime,
              endTime: endDate.format("YYYY-MM-DD") + " " + endTime,
              len,
              values,
            });
            return;
          }

          // 男士才能请护理假
          if (this.isPaternity) {
            if (this.gender !== "Male") {
              this.$message.error("只有男士才能请" + this.typeObject.name);
              return;
            }
          }

          // 女士才能请产假，产检，哺乳假
          if (this.isBirth || this.isCheck || this.isFeed) {
            if (this.gender !== "FeMale") {
              this.$message.error("只有女士才能请" + this.typeObject.name);
              return;
            }
          }

          // 婚假，产假，产检假，护理假，必须上传附件
          // this.isCheck ||
          if (this.isMarry || this.isBirth || this.isPaternity) {
            if (this.fileList.length === 0) {
              this.$message.error("请上传附件");
              return;
            }
          }

          if (this.isCarry) {
            if (this.fileList.length === 0) {
              this.$message.error(
                "请上传独生子女证明，生病亲属身份证和住院相关手续"
              );
              return;
            }
          }

          if (this.isTakeCare) {
            if (this.fileList.length === 0) {
              this.$message.error("请上传三周岁以内子女出生证明或身份证");
              return;
            }
          }

          if (this.isBirth) {
            let len = 158;

            if (values.optionList) {
              let birthTypeLength = 0;
              let childrenLength = 0;
              let errorLength = 0;

              values.optionList.forEach((item) => {
                if (
                  birthTypeList.findIndex((element) => element.label === item) >
                  -1
                ) {
                  birthTypeLength++;
                }
                if (
                  children.findIndex((element) => element.label === item) > -1
                ) {
                  childrenLength++;
                }
                if (
                  errorList.findIndex((element) => element.label === item) > -1
                ) {
                  errorLength++;
                }
              });

              if (errorLength > 0) {
                if (errorLength > 1) {
                  this.$message.error("请选择一种终止妊娠");
                  return;
                }

                if (childrenLength > 0 || birthTypeLength > 0) {
                  this.$message.error("已选择终止妊娠，请手动取消其他类型");
                  return;
                }

                const startDate = values.startDate.clone();
                const endDate = values.endDate.clone();
                const startTime = values.startTime;
                const endTime = values.endTime;

                const len = this.getLen({
                  type: this.type,
                  startDate,
                  startTime,
                  endDate,
                  endTime,
                });

                console.log("len", len);

                if (!len) return;

                const obj = errorList.find(
                  (item) => item.label === values.optionList[0]
                );
                if (len > obj.value) {
                  this.$message.error("请假时间过长");
                  return;
                }

                this.sendRequest({
                  startTime: startDate.format("YYYY-MM-DD") + " " + startTime,
                  endTime: endDate.format("YYYY-MM-DD") + " " + endTime,
                  len,
                  values: {
                    ...values,
                    option: values.optionList[0],
                  },
                });

                return;
              }

              if (childrenLength > 0) {
                if (childrenLength > 1) {
                  this.$message.error("请选择一种多胞胎");
                  return;
                }
              }

              values.optionList.forEach((item) => {
                const element = birthTypeList.find(
                  (element) => element.label === item
                );
                if (element) {
                  len = len + element.value;
                }
                const element1 = children.find(
                  (element) => element.label === item
                );
                if (element1) {
                  len = len + element1.value;
                }
              });
            }

            const startDate = values.startDate;
            const endDate = startDate.clone().add(len - 1, "days");

            console.log("startDate", startDate);
            console.log("endDate", endDate);

            const arr = [];

            for (let i = 0; i < this.holiday.length; i++) {
              const item = this.holiday[i];
              if (Array.isArray(item.data)) {
                for (let j = 0; j < item.data.length; j++) {
                  const element = item.data[j];
                  console.log("element", element);

                  if (Array.isArray(element.realHoliday)) {
                    element.realHoliday.forEach((real) => {
                      if (
                        startDate.isSameOrBefore(real, "days") &&
                        endDate.isSameOrAfter(real, "days")
                      ) {
                        arr.push(real);
                      }
                    });
                  }
                }
              }
            }

            console.log("arr", arr);

            console.log("old len", len);

            len =
              len +
              arr.filter((item, index, self) => self.indexOf(item) === index)
                .length;

            console.log("new len", len);

            const startTime =
              values.startDate.format("YYYY-MM-DD") +
              " " +
              this.startTimeList[0].value;

            // 06-08 ～ 06-09 之间有两天，但差值只有一天
            const endTime =
              values.startDate
                .clone()
                .add(len - 1, "days")
                .format("YYYY-MM-DD") +
              " " +
              this.endTimeList[1].value;

            console.log("starttime", startTime, endTime);

            this.sendRequest({
              startTime,
              endTime,
              len,
              values: {
                ...values,
                option: Array.isArray(values.optionList)
                  ? values.optionList.join(",")
                  : undefined,
              },
            });

            return;
          }

          if (this.isFeed) {
            const endDate = values.startDate
              .clone()
              .add(1, "years")
              .subtract(1, "days");

            let diff = endDate.diff(values.startDate, "days");
            console.log(" diff", diff);

            const startTime =
              values.startDate.format("YYYY-MM-DD") +
              " " +
              this.startTimeList[0].value;

            const endTime =
              endDate.format("YYYY-MM-DD") + " " + this.endTimeList[1].value;

            this.sendRequest({
              startTime,
              endTime,
              len: diff + 1,
              values,
            });

            return;
          }

          const startDate = values.startDate.clone();
          const endDate = values.endDate.clone();
          const startTime = values.startTime;
          const endTime = values.endTime;

          // 默认假期最短可以请半天，丧假，产假和婚假最短一天，出差也是最短一天

          // 不是同一天，就说明用户肯定请了一天假，比如今天下午和明天上午

          // this.isBusiness  出差现在可以请半天

          if (this.isDie || this.isMarry || this.isCarry || this.isTakeCare) {
            if (endDate.isSame(startDate, "day")) {
              if (
                startTime !== this.startTimeList[0].value ||
                endTime !== this.endTimeList[1].value
              ) {
                this.$message.error("最少请一天");
                return;
              }
            }
          }

          if (this.isBusiness) {
            if (this.staffList.length > 0) {
              if (
                this.staffList.find((item) => item.userId === this.user.uuid)
              ) {
                this.$message.error("同行人员请勿选自己");
                return;
              }
            }
          }

          const len = this.getLen({
            type: this.type,
            startDate,
            startTime,
            endDate,
            endTime,
          });

          console.log("len", len);

          if (!len) return;

          if (this.isYear) {
            if (len > this.yearResidue) {
              this.$message.error("您的剩余年假不足");
              return;
            }
          }

          if (this.isSick) {
            if (len > 3) {
              if (this.fileList.length === 0) {
                this.$message.error("请上传三级以上医院证明");
                return;
              }
            } else if (len > 1) {
              if (this.fileList.length === 0) {
                this.$message.error("请上传医院证明");
                return;
              }
            }
          }

          if (this.isMarry) {
            if (len > 13) {
              this.$message.error("婚假不得超过13天");
              return;
            }
          }

          if (this.isCheck) {
            if (len > 4) {
              this.$message.error("产检假不可超过4天");
              return;
            }
          }

          if (this.isPaternity) {
            if (len > 15) {
              this.$message.error("护理假不可超过15天");
              return;
            }
          }

          if (this.isDie) {
            if (values.option === "直系亲属") {
              if (len > 3) {
                this.$message.error("直系亲属丧假不能超过3天");
                return;
              }
            } else if (values.option === "其他亲属") {
              if (len > 1) {
                this.$message.error("其他亲属丧假不能超过1天");
                return;
              }
            }
          }

          // 独生子女带薪护理假：一年内最多申请5天；育儿假：一年内最多申请10天。
          if (this.isCarry) {
            if (len > 5) {
              this.$message.error("独生子女带薪护理假一年内最多申请5天");
              return;
            }
            this.check("single_child_carry", startDate, endDate, {
              startTime: startDate.format("YYYY-MM-DD") + " " + startTime,
              endTime: endDate.format("YYYY-MM-DD") + " " + endTime,
              len,
              values,
            });
          } else if (this.isTakeCare) {
            if (len > 10) {
              this.$message.error("育儿假一年内最多申请10天");
              return;
            }
            this.check("childcare", startDate, endDate, {
              startTime: startDate.format("YYYY-MM-DD") + " " + startTime,
              endTime: endDate.format("YYYY-MM-DD") + " " + endTime,
              len,
              values,
            });
          } else {
            this.sendRequest({
              startTime: startDate.format("YYYY-MM-DD") + " " + startTime,
              endTime: endDate.format("YYYY-MM-DD") + " " + endTime,
              len,
              values,
            });
          }
        }
      });
    },

    check(key, startDate, endDate, params) {
      const startYear = parseInt(startDate.format("YYYY"));
      const endYear = parseInt(endDate.format("YYYY")) + 1;

      // 获取育儿假或带薪护理假的长度，来判断用户今年是否可以请假
      this.saveLoading = true;
      fetchUserLeave({
        startTime: startYear + "-01-01 00:00:00",
        endTime: endYear + "-01-01 00:00:00",
        statusList: ["reviewing", "approved"],
        type: key,
      })
        .then((res) => {
          this.saveLoading = false;
          if (Array.isArray(res)) {
            const list = Object.freeze(
              res.filter((item) => item.optType !== "cancel")
            );

            if (list.length === 0) {
              // 没请过这种假，直接通过
              this.sendRequest(params);
            } else {
              const yearDiff = endYear - startYear;

              let max = 0;
              if (key === "single_child_carry") {
                max = 5;
              } else if (key === "childcare") {
                max = 10;
              }

              if (yearDiff > 1) {
                // 跨年

                let startSum = 0;
                let endSum = 0;
                list.forEach((item) => {
                  if (
                    item.startTime.substring(0, 4) ===
                    item.endTime.substring(0, 4)
                  ) {
                    console.log(
                      item.startTime,
                      item.endTime,
                      "这条请假记录是同年的记录"
                    );
                    console.log("startYear", startYear);
                    if (String(startYear) === item.startTime.substring(0, 4)) {
                      startSum += item.duration;
                    } else {
                      endSum += item.duration;
                    }
                  } else {
                    console.log(
                      item.startTime,
                      item.endTime,
                      "这条请假记录不是同一年"
                    );
                    const itemStartLen = this.getLen({
                      type: key,
                      startDate: moment(item.startTime),
                      startTime: item.startTime.split(" ")[1],
                      endDate: moment(
                        item.startTime.substring(0, 4) + "-12-31"
                      ),
                      endTime: "17:30:00",
                    });

                    console.log("itemStartLen", itemStartLen);

                    startSum += itemStartLen;

                    const itemEndLen = this.getLen({
                      type: key,
                      startDate: moment(
                        item.endTime.substring(0, 4) + "-01-01"
                      ),
                      startTime: "09:00:00",
                      endDate: moment(item.endTime),
                      endTime: item.endTime.split(" ")[1],
                    });

                    console.log("itemEndLen", itemEndLen);

                    endSum += itemEndLen;
                  }
                });

                console.log("startSum, endSum", startSum, endSum);

                const startLen = this.getLen({
                  type: key,
                  startDate: params.values.startDate.clone(),
                  startTime: params.values.startTime,
                  endDate: moment(startYear + "-12-31"),
                  endTime: "17:30:00",
                });

                console.log("startLen", startLen);

                if (!startLen) return;

                let startAll = startSum + startLen;

                console.log("startAll", startAll);

                if (startAll > max) {
                  this.$message.error(`已超过${startYear}年额度，无法再申请`);
                } else {
                  console.log(
                    `${startYear}小于${max}天，还需要判断下一年有没有超过`
                  );

                  const endLen = this.getLen({
                    type: key,
                    startDate: moment(
                      params.values.endDate.format("YYYY") + "-01-01"
                    ),
                    startTime: "09:00:00",
                    endDate: params.values.endDate.clone(),
                    endTime: params.values.endTime,
                  });

                  console.log("endLen", endLen);

                  if (!endLen) return;

                  let endAll = endSum + endLen;
                  console.log("endAll", endAll);

                  if (endAll > max) {
                    this.$message.error(
                      `已超过${params.values.endDate.format(
                        "YYYY"
                      )}年额度，无法再申请`
                    );
                  } else {
                    console.log(`下一年小于${max}天，可以发审批`);
                    this.sendRequest(params);
                  }
                }
              } else {
                // 不跨年

                let sum = 0;
                // list.forEach(item => {
                //   sum += item.duration;
                // });

                list.forEach((item) => {
                  if (
                    item.startTime.substring(0, 4) ===
                    item.endTime.substring(0, 4)
                  ) {
                    console.log(
                      item.startTime,
                      item.endTime,
                      "这条请假记录是同年的记录"
                    );

                    sum += item.duration;
                  } else {
                    console.log(
                      item.startTime,
                      item.endTime,
                      "这条请假记录不是同一年"
                    );

                    if (item.startTime.substring(0, 4) === String(startYear)) {
                      console.log("上年的时间", item.startTime);

                      const itemStartLen = this.getLen({
                        type: key,
                        startDate: moment(item.startTime),
                        startTime: item.startTime.split(" ")[1],
                        endDate: moment(
                          item.startTime.substring(0, 4) + "-12-31"
                        ),
                        endTime: "17:30:00",
                      });

                      console.log("itemStartLen", itemStartLen);

                      sum += itemStartLen;
                    } else {
                      const itemEndLen = this.getLen({
                        type: key,
                        startDate: moment(
                          item.endTime.substring(0, 4) + "-01-01"
                        ),
                        startTime: "09:00:00",
                        endDate: moment(item.endTime),
                        endTime: item.endTime.split(" ")[1],
                      });

                      console.log("itemEndLen", itemEndLen);

                      sum += itemEndLen;
                    }
                  }
                });

                let all = sum + params.len;

                console.log("all", all);

                if (all > max) {
                  this.$message.error(`已超过${startYear}年额度，无法再申请`);
                } else {
                  console.log(`${startYear}小于${max}天，可以发审批`);
                  this.sendRequest(params);
                }
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.saveLoading = false;
        });
    },

    sendRequest({ startTime, endTime, len, values }) {
      this.saveLoading = true;

      const params = {
        isAdminOpt: false,
        allUserFlag: false,

        userIdList: [this.user.uuid],
        type: this.type,
        startTime,
        endTime,

        duration: len,

        durationUnit: this.isOut || this.isDaysOff || this.isOvertime ? "hour" : "day",

        remark: values.remark,

        targetAddr: values.targetAddr,

        option: values.option,

        cost: values.cost,
        staff: values.staff,
        trafficTo: values.trafficTo,
        trafficBack: values.trafficBack,
        isResident: values.isResident,
        // 附件
        attachments:
          this.fileList.length > 0
            ? this.fileList.map((item) => item.completePath).join(",")
            : undefined,
      };

      if (this.control === "resubmit") {
        resubmitLeave({
          ...params,
          optType: this.detail.optType,
          pid: this.detail.pid,
          id: this.id ? this.id : undefined,

          staffList:
            this.isBusiness && this.staffList.length > 0
              ? this.staffList
              : undefined,
        })
          .then(() => {
            this.$router.go(-2);
          })
          .finally(() => {
            this.saveLoading = false;
          });
      } else {
        addLeave({
          ...params,
          optType: this.id ? "modify" : "add",
          pid: this.id ? this.id : undefined, // 出差变更申请需要根据 id 构造 pid

          staffList:
            this.isBusiness && this.staffList.length > 0
              ? this.staffList.map((item) => {
                  return {
                    ...item,
                    pid: item.id ? item.id : undefined,
                  };
                })
              : undefined,
        })
          .then(() => {
            this.$router.go(-1);
          })
          .finally(() => {
            this.saveLoading = false;
          });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.pane {
  background-color: #fff;
  padding: 16px 32px;
  position: relative;

  .title {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.85);
  }

  .remarks {
    margin-top: 8px;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.45);
  }
}

.footer {
  padding: 80px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>