<template>
  <div>
    <EmployeeSelector :single="true" @change="onSelect">
      <a-button type="primary" :disabled="disabled">添加</a-button>
    </EmployeeSelector>

    <Padding />

    <a-table bordered :data-source="list" :pagination="false" rowKey="userId">
      <a-table-column title="姓名" data-index="userName" />
      <a-table-column title="操作" align="center" width="120px" v-if="!disabled">
        <template slot-scope="text, record, index">
          <a href="#" class="danger" @click.prevent="remove(index)">删除</a>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>
  
<script>
import EmployeeSelector from "@/components/employee-selector";
export default {
  components: {
    EmployeeSelector
  },

  props: {
    list: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onSelect(list) {
      console.log("list", list);
      const arr = [
        ...this.list,
        ...list.map(item => {
          return {
            userId: item.userId,
            userName: item.name,
            deptId: item.deptId
          };
        })
      ];

      const result = [];
      arr.forEach(item => {
        if (!result.find(element => element.userId === item.userId)) {
          result.push(item);
        }
      });

      this.$emit("change", result);
    },

    remove(index) {
      const arr = [...this.list];
      arr.splice(index, 1);
      this.$emit("change", arr);
    }
  }
};
</script>