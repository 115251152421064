export const birthTypeList = [

    {
        label: '难产',
        value: 15,
    }, {
        label: '剖腹产',
        value: 15,
    },
];

export const children = [
    {
        label: '双胞胎',
        value: 15,
    }, {
        label: '三胞胎',
        value: 30,
    },
    {
        label: '四胞胎',
        value: 45,
    },
]

export const errorList = [
    {
        label: '不满2个月终止妊娠',
        value: 20
    },
    {
        label: '满2个月不满3个月终止妊娠',
        value: 30,
    },
    {
        label: '满3个月不满7个月终止妊娠',
        value: 42,
    },
    {
        label: '怀孕满7个月终止妊娠',
        value: 98,
    }
]